import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { Injectable } from '@angular/core'

export interface ITicket {
  admissionId: string
  firstName?: string
  lastName?: string
  email?: string
}

export interface ICart {
  promo?: string
  tickets: ITicket[]
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: ICart = { tickets: [] }

  public tickets: { [admissionId: string]: any } = {}
  public promo: any

  constructor(private apollo: Apollo) {}

  public resetCart() {}

  async getOrder(orderId: string) {
    const { data, errors } = await this.apollo
      .query({
        query: gql`
          query Pages_GetOrder($orderId: ID!) {
            getOrder(orderId: $orderId) {
              id
              eventId
              event {
                id
                org {
                  id
                  integrations {
                    id
                    connection {
                      id
                      status
                      settings {
                        key
                        value
                      }
                    }
                  }
                }
              }
              forms {
                id
                type
                fields {
                  id
                  label
                  hintRendered
                  type
                  order
                  options
                  required
                }
              }
              tickets {
                id
                firstName
                lastName
                email
                admissionId
                admission {
                  id
                  forms {
                    id
                    name
                    descriptionRendered
                    showHeader
                    type
                    fields {
                      id
                      label
                      hintRendered
                      type
                      order
                      options
                      required
                    }
                  }
                }
              }
              user {
                id
                email
              }
              currentTransaction {
                paymentIntent {
                  id
                  clientSecret
                }
              }
              paymentType
              promoCode
              notes
              promo {
                id
                code
                discount
                type
                status
                forms {
                  id
                  type
                  fields {
                    id
                    label
                    hintRendered
                    type
                    order
                    options
                    required
                  }
                }
              }
              summary {
                tickets {
                  admissionId
                  admission {
                    id
                    name
                    type
                  }
                  amount
                  quantity
                  discount
                }
                total
                subtotal
                fees
                discount
              }
            }
          }
        `,
        variables: { orderId }
      })
      .toPromise()

    return data && data['getOrder']
  }

  async createOrder(order: any) {
    const { data } = await this.apollo
      .mutate({
        mutation: gql`
          mutation Pages_CreateOrder($order: RegistrationOrderInput!) {
            createOrder(order: $order) {
              id
            }
          }
        `,
        variables: {
          order
        }
      })
      .toPromise()

    return data && data['createOrder']
  }

  async updateOrder(orderId: string, order: any) {
    const updatedOrder = {
      promoCode: order.promoCode,
      eventId: order.eventId,
      paymentType: order.paymentType,
      notes: order.notes,
      tickets: [],
      metadata: order.metadata
    }

    for (const ticket of order.tickets) {
      updatedOrder.tickets.push({
        id: ticket.id,
        admissionId: ticket.admissionId,
        email: ticket.email,
        firstName: ticket.firstName,
        lastName: ticket.lastName,
        metadata: ticket.metadata
      })
    }

    const { data } = await this.apollo
      .mutate({
        mutation: gql`
          mutation Pages_UpdateOrder(
            $orderId: ID!
            $order: RegistrationOrderInput!
          ) {
            updateOrder(orderId: $orderId, order: $order) {
              id
            }
          }
        `,
        variables: {
          orderId,
          order: updatedOrder
        }
      })
      .toPromise()

    return data && data['updateOrder']
  }
}
