<div class="modal-header">
  <h4 class="modal-title">Thanks for registering!</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <img
    src="assets/confirmed.svg"
    alt="confirmed image"
    class="img-fluid m-4"
    style="max-width: 200px;"
  />
  <p>Yay! You successfully registered for {{ event.name }}!</p>
  <p>We just sent your receipt and ticket to your email address.</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
