import { ThanksModalComponent } from './../thanks-modal/thanks-modal.component'
import { PaymentInfoComponent } from './payment-info/payment-info.component'
import { TicketInfoComponent } from './ticket-info/ticket-info.component'
import { CartService } from './../../../services/cart.service'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { faLock } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public cartSvc: CartService
  ) {
    this.form = new UntypedFormGroup({})
  }
  @ViewChild('ticketInfo') ticketInfo: TicketInfoComponent
  @ViewChild('paymentInfo')
  paymentInfo: PaymentInfoComponent
  @Input() orderId: string
  @Input() event: any

  public activeTab: string = 'tickets'
  public order: any

  public form: UntypedFormGroup
  public faLock = faLock

  public loaded = false
  public formsLoaded = false
  public loadingStep = false

  public admissionTypes = {}

  ngOnInit() {
    this.loadOrder()
  }

  async loadOrder() {
    for (const admission of this.event.admissions) {
      this.admissionTypes[admission.id] = admission
    }

    this.order = await this.cartSvc.getOrder(this.orderId)

    this.loaded = true

    // this is a horrible hack to ensure that all form controls in
    // child components are loaded before canContinue is able to evaluate as true
    setTimeout(() => {
      this.formsLoaded = true
    }, 50)
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field)
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true })
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control)
      }
    })
  }

  async runValidation(): Promise<boolean> {
    let isValid = true

    if (this.form.invalid) {
      isValid = false
      this.validateAllFormFields(this.form)
    }

    let tabValid = true
    if (this.activeTab === 'tickets') {
      tabValid = await this.ticketInfo.isValid()
    } else if (this.activeTab === 'payment') {
      tabValid = await this.paymentInfo.isValid()
    }

    if (!tabValid) {
      isValid = false
    }

    return isValid
  }

  async nextStep() {
    this.loadingStep = true
    const isValid = await this.runValidation()

    if (!isValid) {
      this.loadingStep = false
      return
    }

    try {
      if (this.activeTab === 'tickets') {
        await this.ticketInfo.next()
        this.activeTab = 'payment'
      } else if (this.activeTab === 'payment') {
        await this.paymentInfo.next()
        this.activeModal.close()
        this.success()
      }
      this.formsLoaded = false

      // this is a horrible hack to ensure that all form controls in
      // child components are loaded before canContinue is able to evaluate as true
      setTimeout(() => {
        this.formsLoaded = true
      }, 50)
    } catch (err) {
      console.log(err)
    } finally {
      this.loadingStep = false
    }
  }

  success() {
    const modalRef = this.modalService.open(ThanksModalComponent, {
      size: 'lg'
    })
    const modalInst: ThanksModalComponent = modalRef.componentInstance
    modalInst.event = this.event
  }

  cancel() {
    this.activeModal.dismiss('Cross click')
    this.cartSvc.resetCart()
  }

  get canContinue(): boolean {
    return this.formsLoaded && this.loaded && !this.loadingStep
  }
}
