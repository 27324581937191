<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Share this event with friends
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-center">
    <a
      *ngFor="let social of socialPlatforms"
      [href]="social.url + url"
      class="btn btn-primary btn-lg mx-2"
    >
      <fa-icon [icon]="social.icon"></fa-icon>
    </a>
  </div>
  <div class="text-center" *ngxClipboardIfSupported>
    <form class="">
      <div class="input-group mt-3">
        <input
          type="text"
          class="form-control mb-2 form-control-lg"
          readonly
          id="shareInput"
          #inputTarget
          [value]="url"
        />

        <div class="input-group-append" #container>
          <input
            type="button"
            class="btn btn-primary mb-2"
            id="shareCopyBtn"
            ngxClipboard
            [ngxClipboard]="inputTarget"
            [container]="container"
            (click)="inputTarget.select()"
            value="Copy"
          />
        </div>
      </div>
    </form>
  </div>
</div>
