<form [formGroup]="form" class="my-4">
  <form [formGroup]="userForm">
    <div class="form-row">
      <div class="col form-group">
        <label for="firstName">First name</label>
        <input
          type="text"
          class="form-control form-control-alternative"
          [ngClass]="invalidClass('firstName')"
          formControlName="firstName"
          id="firstName"
        />
        <div class="invalid-feedback">Please enter a first name</div>
      </div>
      <div class="col form-group">
        <label for="lastName">Last name</label>
        <input
          type="text"
          class="form-control form-control-alternative"
          [ngClass]="invalidClass('lastName')"
          formControlName="lastName"
          id="lastName"
        />
        <div class="invalid-feedback">Please enter a last name</div>
      </div>
    </div>

    <form [formGroup]="addressForm">
      <div class="form-row">
        <div class="col form-group">
          <label for="address">Address</label>
          <input
            type="text"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('line1', addressForm)"
            formControlName="line1"
            id="address"
          />
          <div class="invalid-feedback">Please enter an address</div>
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <button
            *ngIf="!showLine2"
            class="btn btn-link"
            (click)="showLine2 = true"
          >
            + Add Line 2
          </button>
          <div *ngIf="showLine2">
            <label for="line2">
              Address Line 2
              <a href="javascript:void(0)" (click)="removeLine2()"> Remove </a>
            </label>
            <input
              type="text"
              class="form-control form-control-alternative"
              formControlName="line2"
              id="line2"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-3 form-group">
          <label for="postalCode">Postal Code</label>
          <input
            type="text"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('postalCode', addressForm)"
            formControlName="postalCode"
            id="postalCode"
          />
          <div class="invalid-feedback">Please enter a postal code</div>
        </div>
        <div class="col-5 form-group">
          <label for="city">City</label>
          <input
            type="text"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('city', addressForm)"
            formControlName="city"
            id="city"
          />
          <div class="invalid-feedback">Please enter a city</div>
        </div>
        <div class="col-4 form-group">
          <label for="state">State / Province</label>
          <select
            class="custom-select form-control-alternative"
            [ngClass]="invalidClass('state', addressForm)"
            formControlName="state"
            id="state"
          >
            <optgroup
              *ngFor="let country of regionsSvc.countries"
              [label]="country.name"
            >
              <option
                *ngFor="let region of regionsSvc.regions[country.id]"
                [ngValue]="region.id"
              >
                {{ region.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </form>
  </form>

  <div *ngIf="customFormsForm && order.forms">
    <app-custom-form
      *ngFor="let form of order.forms"
      [form]="customFormsForm"
      [customForm]="form"
    ></app-custom-form>
  </div>

  <div class="row" *ngIf="order.summary.total > 0">
    <div class="col">
      <div class="form-group" *ngIf="event.registration.allowOfflinePayments">
        <label for="paymentType">Payment Method</label>
        <select
          name="paymentType"
          id="paymentType"
          formControlName="paymentType"
          class="form-control form-control-alternative"
        >
          <option value="cc">Credit Card</option>
          <option value="offline">Check</option>
        </select>
      </div>

      <div
        class="form-group"
        *ngIf="
          paymentType === 'offline' &&
          event.registration.offlinePaymentInstructions
        "
      >
        <p>{{ event.registration.offlinePaymentInstructions }}</p>
      </div>

      <div id="payment-element"></div>

      <div class="form-group" *ngIf="paymentType === 'cc'">
        <p class="text-danger" *ngIf="error">{{ error.message }}</p>
        <p class="text-danger" *ngIf="cardStatus && cardStatus.error">
          {{ cardStatus.error.message }}
        </p>
        <p
          class="text-danger"
          *ngIf="
            showCardStatus &&
            (!cardStatus ||
              (cardStatus && !cardStatus.error && !cardStatus.complete))
          "
        >
          Credit card is required.
        </p>
      </div>
    </div>
  </div>

  <div
    class="custom-control custom-checkbox mt-4 mb-3"
    [ngClass]="invalidClass('tos', form)"
  >
    <input
      type="checkbox"
      class="custom-control-input"
      [ngClass]="invalidClass('tos', form)"
      id="acceptedTerms"
      formControlName="tos"
    />
    <label class="custom-control-label" for="acceptedTerms">
      I accept eventOne's
      <a href="https://e1.lc/app-tos" target="_blank" rel="noopener">
        Terms of Service
      </a>
      and
      <a href="https://e1.lc/app-privacy" target="_blank" rel="noopener">
        Privacy Policy</a
      ><ng-container *ngIf="event.registration.policy">
        as well as the event's
        <a href="javascript:void(0)" (click)="open(content)">
          Terms and Conditions
        </a></ng-container
      >.
    </label>
  </div>

  <div class="invalid-feedback">You must accept the terms.</div>
</form>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-terms-title">Terms and Conditions</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ event.registration.policy }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Okay')"
    >
      Okay
    </button>
  </div>
</ng-template>
