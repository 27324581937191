import { LocationService } from '../../services/location.service'
import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {
  public url: string

  socialPlatforms = [
    {
      icon: faFacebookF,
      url: 'https://www.facebook.com/sharer.php?u='
    },
    {
      icon: faTwitter,
      url: 'https://twitter.com/intent/tweet?status='
    },
    {
      icon: faLinkedinIn,
      url: 'https://www.linkedin.com/shareArticle?mini=true&url='
    },
    {
      icon: faAt,
      url: 'mailto:?body='
    }
  ]

  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationService
  ) {}

  ngOnInit() {
    this.url = this.location.getCanonical()
  }
}
