import { CartService } from './../../../../services/cart.service'
import { UntypedFormGroup } from '@angular/forms'
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss']
})
export class TicketInfoComponent implements OnInit {
  @Input() order: any
  @Input() event: any
  @Input() form: UntypedFormGroup

  constructor(public cartSvc: CartService) {}

  ngOnInit() {
    this.form.addControl('tickets', new UntypedFormGroup({}))
  }

  async isValid(): Promise<boolean> {
    return true
  }

  async next() {
    for (const id in this.form.value.tickets) {
      if (this.form.value.tickets[id]) {
        const attendee = this.form.value.tickets[id]
        const ticket = this.order.tickets.find(t => t.id === id)

        ticket.email = attendee.email
        ticket.firstName = attendee.firstName
        ticket.lastName = attendee.lastName
        ticket.metadata = ticket.metadata || []

        if (attendee.userId) {
          const attendeeData = this.form.value.tickets[attendee.userId]
          ticket.email = attendeeData.email
          ticket.firstName = attendeeData.firstName
          ticket.lastName = attendeeData.lastName
        }

        for (const metaId in attendee.metadata) {
          if (attendee.metadata[metaId]) {
            ticket.metadata.push({
              fieldId: metaId,
              value: attendee.metadata[metaId]
            })
          }
        }
      }
    }

    await this.cartSvc.updateOrder(this.order.id, this.order)
  }
}
