<form [formGroup]="form">
  <app-ticket-form
    *ngFor="let ticket of order.tickets; let i = index"
    [index]="i + 1"
    [event]="event"
    [ticket]="ticket"
    [form]="form.controls['tickets']"
    [tickets]="order.tickets"
  ></app-ticket-form>
</form>
