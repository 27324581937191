<div class="card my-4">
  <div class="card-header">
    {{ admissionTypeName[admissionType.type] }} Information
  </div>
  <div class="card-body">
    <h5 class="card-title">
      {{ index }}. {{ admissionType.name }}
    </h5>

    <form [formGroup]="ticketForm">
      <div *ngIf="admissionType.type === 'ticket'">
        <div class="form-group">
          <label [for]="'email' + ticket.id">
            Email
          </label>
          <input
            type="email"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('email')"
            formControlName="email"
            [id]="'email' + ticket.id"
            placeholder="name@example.com"
          />
          <div class="invalid-feedback">
            Please enter a valid email address
          </div>
        </div>
        <div class="form-group">
          <label [for]="'firstName' + ticket.id">
            First name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('firstName')"
            formControlName="firstName"
            [id]="'firstName' + ticket.id"
          />
          <div class="invalid-feedback">
            Please enter a first name
          </div>
        </div>
        <div class="form-group">
          <label [for]="'lastName' + ticket.id">
            Last name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control form-control-alternative"
            [ngClass]="invalidClass('lastName')"
            formControlName="lastName"
            [id]="'lastName' + ticket.id"
          />
          <div class="invalid-feedback">
            Please enter a last name
          </div>
        </div>
      </div>

      <div *ngIf="admissionType.type === 'addon'">
        <div class="form-group">
          <label [for]="'attendee' + ticket.id">Attendee</label>
          <select
            class="custom-select form-control-alternative"
            formControlName="userId"
            [id]="'userId' + ticket.id"
          >
            <option selected [value]="null">None</option>
            <option
              *ngFor="let attendee of attendees; let i = index"
              [value]="attendee.id"
            >
              Attendee #{{ attendee.index }}
              <span
                *ngIf="
                  attendee.form &&
                  (attendee.form.firstName || attendee.form.lastName)
                "
              >
                - {{ attendee.form.firstName }} {{ attendee.form.lastName }}
              </span>
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="customFormsForm && ticket.admission.forms">
        <app-custom-form
          *ngFor="let form of ticket.admission.forms"
          [form]="customFormsForm"
          [customForm]="form"
          [id]="ticket.id"
        ></app-custom-form>
      </div>
    </form>
  </div>
</div>
