import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})
export class TicketFormComponent implements OnInit {
  @Input() form: UntypedFormGroup
  @Input() ticket: any

  @Input() index: number
  @Input() event: any
  @Input() tickets: any[]

  ticketForm: UntypedFormGroup
  customFormsForm: UntypedFormGroup

  public admissionTypes = {}

  public admissionTypeName = {
    addon: 'Add-on',
    ticket: 'Attendee'
  }

  get attendees() {
    return this.tickets
      .map((t, i) => {
        t.index = i + 1

        return t
      })
      .filter(t => {
        return (
          this.admissionTypes[t.admissionId] &&
          this.admissionTypes[t.admissionId].type &&
          this.admissionTypes[t.admissionId].type === 'ticket'
        )
      })
      .map(a => {
        const attendeeForm = this.form.controls[a.id]
        a.form = attendeeForm && attendeeForm.value

        return a
      })
  }

  get admissionType() {
    return this.admissionTypes[this.ticket.admissionId]
  }

  constructor() {}

  invalidClass(type: string) {
    const control = this.ticketForm.controls[type]
    return { 'is-invalid': control.invalid && control.touched }
  }

  ngOnInit() {
    for (const admission of this.event.admissions) {
      this.admissionTypes[admission.id] = admission
    }

    this.ticketForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [
        this.admissionType.type === 'ticket'
          ? Validators.required
          : Validators.nullValidator
      ]),
      lastName: new UntypedFormControl(null, [
        this.admissionType.type === 'ticket'
          ? Validators.required
          : Validators.nullValidator
      ]),
      email: new UntypedFormControl(null, [Validators.email]),
      userId: new UntypedFormControl(null, []),
      metadata: new UntypedFormGroup({})
    })

    this.form.addControl(this.ticket.id, this.ticketForm)

    this.customFormsForm = this.ticketForm.controls['metadata'] as UntypedFormGroup
  }
}
