import { Component, OnInit, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-thanks-modal',
  templateUrl: './thanks-modal.component.html',
  styleUrls: ['./thanks-modal.component.scss']
})
export class ThanksModalComponent implements OnInit {
  @Input() event: any

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
