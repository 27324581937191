import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CartService } from './../../../../services/cart.service'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Component, OnInit, Input } from '@angular/core'
import { StripeElements, Stripe, StripePaymentElement } from '@stripe/stripe-js'
import { RegionsService } from 'src/app/services/regions.service'
import { StripeService } from 'src/app/services/stripe.service'

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  @Input() order: any
  @Input() event: any
  @Input() form: UntypedFormGroup

  userForm: UntypedFormGroup
  addressForm: UntypedFormGroup
  customFormsForm: UntypedFormGroup

  showLine2 = false
  elements: StripeElements
  element: StripePaymentElement
  stripe: Stripe
  user: any

  cardStatus: any
  showCardStatus = false

  error: any

  get paymentType() {
    return this.form.controls['paymentType'].value
  }

  constructor(
    public regionsSvc: RegionsService,
    private apollo: Apollo,
    private cartSvc: CartService,
    private stripeSvc: StripeService,
    private modalService: NgbModal
  ) {}

  invalidClass(name: string, group = this.userForm) {
    const control = group.controls[name]
    return { 'is-invalid': control.invalid && control.touched }
  }

  async initStripe() {
    this.stripe = await this.stripeSvc.initStripe(this.order.event.org)

    this.form.controls['paymentType'].valueChanges.subscribe((change) => {
      if (change === 'cc') {
        setTimeout(() => {
          this.createElement()
        }, 0)
      } else {
        this.element = null
      }
    })

    if (this.order.summary.total > 0) {
      setTimeout(() => {
        this.createElement()
      }, 0)
    }
  }

  ngOnInit() {
    const controls = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      address: new UntypedFormGroup({
        line1: new UntypedFormControl(null, [Validators.required]),
        line2: new UntypedFormControl(null, []),
        city: new UntypedFormControl(null, [Validators.required]),
        state: new UntypedFormControl(null, [Validators.required]),
        postalCode: new UntypedFormControl(null, [Validators.required])
      })
    })

    this.form.addControl('user', controls)
    this.form.addControl(
      'tos',
      new UntypedFormControl(false, [Validators.requiredTrue])
    )
    this.form.addControl(
      'paymentType',
      new UntypedFormControl('cc', [Validators.required])
    )

    this.userForm = this.form.controls['user'] as UntypedFormGroup
    this.addressForm = this.userForm.controls['address'] as UntypedFormGroup

    this.customFormsForm = new UntypedFormGroup({})

    this.initStripe()

    this.getUser()
  }

  createElement() {
    this.elements = this.stripe.elements({
      appearance: this.stripeSvc.getElementsAppearance(),
      clientSecret: this.order.currentTransaction.paymentIntent.clientSecret
    })

    // Only mount the element the first time
    if (!this.element) {
      this.element = this.elements.create('payment', {
        fields: {
          billingDetails: {
            email: 'never',
            name: 'never',
            address: 'never'
          }
        }
      })
      this.element.mount('#payment-element')

      this.element.on('change', (ev) => {
        this.showCardStatus = false
        this.cardStatus = ev
      })
    }
  }

  async getUser() {
    const { data, errors } = await this.apollo
      .query({
        query: gql`
          query Pages_GetUser {
            me {
              id
              email
              firstName
              lastName
              address {
                line1
                line2
                city
                state
                postalCode
                country
              }
            }
          }
        `
      })
      .toPromise()

    this.user = data && data['me']
    if (this.user) {
      this.userForm.patchValue(this.user)

      if (this.user.address && this.user.address.line2) {
        this.showLine2 = true
      }
    }
  }

  async updateUser(user: any) {
    const { data, errors } = await this.apollo
      .mutate({
        mutation: gql`
          mutation Pages_UpdateUser($user: UserInput!, $id: ID!) {
            updateUser(user: $user, id: $id) {
              id
            }
          }
        `,
        variables: {
          user: {
            ...user,
            email: this.user.email
          },
          id: this.user.id
        }
      })
      .toPromise()
  }

  async finalizeOrder(orderId: string, paymentIntentId?: string) {
    const { data, errors } = await this.apollo
      .mutate({
        mutation: gql`
          mutation Pages_FinalizeOrder(
            $orderId: ID!
            $paymentIntentId: String
          ) {
            finalizeOrder(
              orderId: $orderId
              paymentIntentId: $paymentIntentId
            ) {
              id
            }
          }
        `,
        variables: {
          orderId,
          paymentIntentId
        }
      })
      .toPromise()
  }

  async isValid(): Promise<boolean> {
    let isValid = true

    // only do card validation if the order is paid
    if (this.order.summary.total > 0 && this.paymentType === 'cc') {
      if (!this.cardStatus || !this.cardStatus.complete) {
        this.showCardStatus = true
        isValid = false
        this.element.blur()
      }
    }

    return isValid
  }

  async next() {
    this.error = null

    const user = { ...this.userForm.value }
    user.address.country = this.regionsSvc.getCountryByRegion(
      user.address.state
    )

    await this.updateUser(user)

    this.order.paymentType = this.paymentType

    this.order.metadata = this.order.metadata || []
    for (const metaId in this.customFormsForm.value) {
      if (this.customFormsForm.value[metaId]) {
        this.order.metadata.push({
          fieldId: metaId,
          value: this.customFormsForm.value[metaId]
        })
      }
    }

    await this.cartSvc.updateOrder(this.order.id, this.order)

    let err: any
    if (this.order.summary.total > 0 && this.paymentType === 'cc') {
      const name = `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()
      const { paymentIntent, error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              name,
              email: this.user.email,
              address: {
                line1: user.address.line1,
                line2: user.address.line2,
                city: user.address.city,
                state: user.address.state,
                country: user.address.country,
                postal_code: user.address.postalCode
              }
            }
          }
        },
        redirect: 'if_required'
      })

      err = error
    }

    // finalize the order
    await this.finalizeOrder(
      this.order.id,
      this.order?.currentTransaction?.paymentIntent?.id
    )

    if (err) {
      // Display error.message in your UI.
      console.log(err)
      this.error = err
      throw err
    } else {
      // The payment has succeeded. Display a success message.
      // console.log(paymentIntent)
      const event = {
        transaction_id: this.order.id,
        affiliation: 'eventOne Registration',
        value: this.order.summary.subtotal,
        currency: 'USD',
        coupon: this.order.promoCode,
        items: []
      }

      for (const ticket of this.order.summary.tickets) {
        event.items.push({
          id: ticket.admission.id,
          name: ticket.admission.name,
          category: ticket.admission.type === 'addon' ? 'Add-on' : 'Ticket',
          quantity: ticket.quantity,
          price: ticket.amount
        })
      }

      window.gtag('event', 'purchase', event)
      if (window.FS) {
        window.FS.event('Order Completed', {
          order_id: this.order.id,
          amount: this.order.summary.subtotal,
          promo_code: this.order.promoCode
        })
      }
      if (window.fathom) {
        window.fathom.trackGoal('7FHEHNPO', this.order.summary.subtotal * 100)
      }
    }
  }

  removeLine2() {
    this.showLine2 = false
    this.addressForm.controls['line2'].setValue(null)
  }

  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-terms-title',
      size: 'sm'
    })
  }
}
