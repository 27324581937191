<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ event.name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-lg">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills nav-fill">
        <li
          ngbNavItem="tickets"
          [disabled]="!formsLoaded || (!loadingStep && activeTab === 'payment')"
        >
          <a ngbNavLink [ariaDisabled]="">1. Tickets</a>
          <ng-template ngbNavContent>
            <app-ticket-info
              #ticketInfo
              *ngIf="loaded"
              [event]="event"
              [form]="form"
              [order]="order"
            ></app-ticket-info>
          </ng-template>
        </li>
        <li
          ngbNavItem="payment"
          [disabled]="!formsLoaded || (!loadingStep && activeTab === 'tickets')"
        >
          <a ngbNavLink>2. Payment</a>
          <ng-template ngbNavContent>
            <app-payment-info
              #paymentInfo
              *ngIf="loaded"
              [event]="event"
              [form]="form"
              [order]="order"
            ></app-payment-info>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="col-lg-5">
      <div class="card order-summary sticky-top bg-secondary">
        <div class="card-body">
          <h5 class="card-title">Order Summary</h5>

          <div *ngIf="loaded">
            <ul class="list-unstyled">
              <div *ngFor="let ticket of order.summary.tickets">
                <li>
                  {{ ticket.quantity }} x
                  {{ admissionTypes[ticket.admissionId].name }}
                  <span class="float-right">
                    {{ ticket.amount | numeral : '$0,0.00' : 'FREE' }}
                  </span>
                </li>
              </div>
            </ul>
            <hr />
            <ul class="list-unstyled">
              <li>
                Subtotal
                <span class="float-right">
                  {{ order.summary.subtotal | numeral : '$0,0.00' }}
                </span>
              </li>
              <li *ngIf="order.summary.discount">
                Discount
                <span class="text-success float-right">
                  -{{ order.summary.discount | numeral : '$0,0.00' }}
                </span>
              </li>
              <li *ngIf="!event.registration.absorbFees">
                Service fee
                <span class="float-right">
                  {{ order.summary.fees | numeral : '$0,0.00' }}
                </span>
              </li>
            </ul>
            <hr />
            <ul class="list-unstyled">
              <li>
                Total
                <strong class="float-right">
                  {{ order.summary.total | numeral : '$0,0.00' }}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-icon btn-lg btn-success btn-block"
    (click)="nextStep()"
    [disabled]="!canContinue"
  >
    <span *ngIf="!loadingStep">
      <fa-icon [icon]="faLock" class="pr-2"></fa-icon>

      Continue
    </span>
    <span *ngIf="loadingStep">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </span>
  </button>
</div>
